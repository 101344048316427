import {
    GenericErrorMessage,
    GenericErrorMessageGetter,
} from './error-codes.types';
import {
    ApplicationIdentifier,
    ErrorCodes,
    MappedErrorCodes,
    ReMappedErrorCodes,
} from '@tgg/common-types';

export const genericPaymentFailureText = 'PAYMENT FAILED';
export const unAuthorisedPaymentFailureText = 'PAYMENT NOT AUTHORISED';
export const unknownFailureText = 'UNKNOWN ERROR';
export const genericFailureText = 'SOMETHING WENT WRONG';
export const invalidPromoCode = 'PROMOCODE IS NOT RECOGNISED';
export const invalidPersonalDetails = 'INVALID PERSONAL DETAILS';
export const registrationFailureText = 'REGISTRATION FAILED';

const errorCodesReMap: Record<ReMappedErrorCodes, MappedErrorCodes> = {
    /**
     * Sage pay
     */
    '4000': '2002',
    '4001': '2001',
    '5000': '2002',
    '5003': '2002',
};

/* istanbul ignore next */
export const errorMessagesForUI: Record<
    MappedErrorCodes,
    GenericErrorMessageGetter
> = {
    '1001': applicationIdentifier => ({
        title: registrationFailureText,
        description: 'We were unable to complete your registration process.',
    }),
    '1002': applicationIdentifier => ({
        title: genericFailureText,
        description: 'We were unable to complete your registration process.',
    }),
    '1003': applicationIdentifier => ({
        title: invalidPromoCode,
        description: 'Invalid Promo Code.',
    }),
    '1004': applicationIdentifier => ({
        title: invalidPersonalDetails,
        description: 'Invalid Personal Details.',
    }),
    '1005': applicationIdentifier => ({
        title: invalidPersonalDetails,
        description: `It looks like we couldn't retrieve your pin. Please try again later or contact us`,
    }),
    /**
     * member error codes
     */
    '6000': applicationIdentifier => ({
        title: genericFailureText,
        description: `It looks like we couldn't get your list of gyms. Please try again later or contact us`,
    }),
    '6001': applicationIdentifier => ({
        title: genericFailureText,
        description: `It looks like we couldn't remove your additional gym. Please try again later`,
    }),
    '6002': applicationIdentifier => ({
        title: genericFailureText,
        description: `It looks like we couldn't add your additional gym. Please try again later`,
    }),
    '6003': applicationIdentifier => ({
        title: genericFailureText,
        description: `There has been a problem updating your information. Please check your details below and try again.`,
    }),
    '6004': applicationIdentifier => ({
        title: genericFailureText,
        description: `It looks like we couldn't remove your yanga subscription. Please try again later`,
    }),
    '6005': applicationIdentifier => ({
        title: genericFailureText,
        description: `It looks like we couldn't get your failed payment details. Please try again later`,
    }),
    /**
     * Payment related errors
     */
    '2000': applicationIdentifier => ({
        title: genericPaymentFailureText,
        description:
            'We were unable to process your payment. Please check your details again or try another payment method.',
    }),
    '2001': applicationIdentifier => ({
        title: unAuthorisedPaymentFailureText,
        description: 'We were unable to authorise your card.',
    }),
    '2002': applicationIdentifier => ({
        title: genericPaymentFailureText,
        description:
            'We were unable to process your payment. Please check your details again or try another payment method.',
    }),
    '2003': applicationIdentifier => ({
        title: genericPaymentFailureText,
        description:
            'We are unable to validate your bank account details at this time. Please go back and use another payment method.',
    }),

    /**
     * other errors that could not be mapped.
     */
    '3001': applicationIdentifier => ({
        title: unknownFailureText,
        description: 'There was an unknown error.',
    }),
};

export const errorMapper = (errorCode: ErrorCodes): MappedErrorCodes =>
    Object.keys(errorCodesReMap).includes(errorCode)
        ? errorCodesReMap[errorCode as keyof typeof errorCodesReMap]
        : Object.keys(errorMessagesForUI).includes(errorCode)
        ? (errorCode as MappedErrorCodes)
        : '3001';

export const getErrorFromErrorCode = (
    code: ErrorCodes,
    applicationIdentifier?: ApplicationIdentifier,
): GenericErrorMessage =>
    errorMessagesForUI[errorMapper(code)](applicationIdentifier);
