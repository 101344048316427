import { type PaymentSubmitHandlerArguments } from '../..';
import {
    type GetPaymentToken,
    processGetPaymentToken,
} from '../getPaymentToken/processGetPaymentToken';
import { getMerchantSessionKey, makePaymentGateway } from './paymentHelpers';
import { type ApplicationIdentifier } from '@tgg/common-types';
import { is3DAuthenticatedResponse } from '@tgg/services';

export interface UsePaymentGatewayProperties {
    applicationIdentifier: ApplicationIdentifier;
    updateJourneyName?: string;
    persistPaymentToken: (
        paymentToken: string,
        storage: Storage,
    ) => void | Promise<void>;
    getPaymentToken: GetPaymentToken;
}

export const usePaymentGateway = ({
    applicationIdentifier,
    updateJourneyName,
    persistPaymentToken,
    getPaymentToken,
}: UsePaymentGatewayProperties) => {
    const paymentHandler = async ({
        formInfo,
        billingAddress,
        termsAccepted,
        setUpThreeDSFlow,
        setCardIdentifier,
        onSuccess,
        isReoccurringCreditCard,
        totalCost,
    }: PaymentSubmitHandlerArguments) => {
        const paymentToken = await processGetPaymentToken({
            getPaymentToken,
            applicationIdentifier,
            formInfo,
        });

        const merchantSessionKey = await getMerchantSessionKey({
            applicationIdentifier,
        });

        const paymentGatewayResponse = await makePaymentGateway({
            formInfo,
            billingAddress,
            merchantSessionKey,
            paymentToken,
            termsAccepted,
            authorisedPayee: termsAccepted,
            setCardIdentifier,
            updateJourneyName,
            applicationIdentifier,
            isReoccurringCreditCard,
            totalCost,
        });

        await persistPaymentToken(
            paymentGatewayResponse.updatedToken,
            sessionStorage,
        );

        if (is3DAuthenticatedResponse(paymentGatewayResponse)) {
            setUpThreeDSFlow({
                paymentGatewayResponse,
                journeyName: updateJourneyName,
                formInfo,
            });
            return;
        }

        await onSuccess({ formInfo });
    };

    return { paymentHandler };
};
