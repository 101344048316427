import { v4 as uuidv4 } from 'uuid';

import {
    CommonBasketCookieBase,
    PaymentLogData,
    Nullable,
} from '@tgg/common-types';

export type GetLoggerPaymentData = {
    basketCookie: Nullable<Partial<CommonBasketCookieBase>>;
    memberId?: string;
};

export const getLoggerPaymentData = ({
    basketCookie,
    memberId,
}: GetLoggerPaymentData) => {
    const { userId, paymentId } = basketCookie ?? {};

    const loggerData: PaymentLogData = {
        userId: memberId ?? userId ?? uuidv4(),
        paymentId: paymentId ?? uuidv4(),
    };

    return loggerData;
};
