import { NextApiRequest } from 'next';

/**
 * Small = 250 x 400
 * Medium = 390 x 400
 * Large = 500 x 600
 * ExtraLarge = 600 x 400
 * FullScreen = Full screen
 */

export const getFrontendStrongCustomerAuthentication = (
    notificationDomain: string,
    challangePath: string,
    updateJourneyName?: string,
    challengeWindowSize:
        | 'Small'
        | 'Medium'
        | 'Large'
        | 'ExtraLarge'
        | 'FullScreen' = 'Small',
) => {
    const urlParameters = updateJourneyName
        ? `?update=${updateJourneyName}`
        : '';

    const notificationDomainDevelopmentTransform = notificationDomain.replace(
        'localhost',
        '127.0.0.1',
    );

    const cleanedNotificationURL =
        notificationDomainDevelopmentTransform.replace(/\/$/, '');

    const cleanedChallangePath = challangePath
        .replace(/^\//, '')
        .replace(/\/$/, '');

    return {
        notificationURL: `${cleanedNotificationURL}/${cleanedChallangePath}/${urlParameters}`,
        browserColorDepth: String(
            // chrome returns 30 for colorDepth on macbook???
            window.screen.colorDepth === 30 ? 24 : window.screen.colorDepth,
        ),
        browserScreenHeight: String(window.screen.height),
        browserScreenWidth: String(window.screen.width),
        browserTZ: String(new Date().getTimezoneOffset()),
        challengeWindowSize,
        website: 'https://www.thegymgroup.com/',
    };
};

export const getBrowserIP = (request: NextApiRequest) => {
    return ((request.headers['cf-connecting-ip'] as string) || '') // This header is injected via cloudflare
        .replace(/ /g, '')
        .split(',')[0]; // TODO: not sure if we need this protection
};

export const getBackendStrongCustomerAuthentication = (
    request: NextApiRequest,
) => {
    return {
        browserIP:
            process.env.NODE_ENV === 'production'
                ? getBrowserIP(request)
                : '127.0.0.1',
        browserAcceptHeader: request.headers.accept,
        browserUserAgent: request.headers['user-agent'],
    };
};
