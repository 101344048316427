import { useEffect, useRef } from 'react';

import { StyledIFrame } from './AuthenticationIframe.styled';
import { AuthenticationIframeProperties } from './AuthenticationIframe.types';
import { clientLogger } from '@tgg/services';
import {
    getFullApplicationIdentifierClient,
    relativeUrlWithBasePath,
} from '@tgg/util';

export function AuthenticationIframe({
    redirectUrl,
    type,
    req,
    transactionId,
    applicationIdentifier,
    updateJourneyName,
    threeDSChallengePath = '/challenge-outcome/',
    triggerTimerCallback,
}: AuthenticationIframeProperties) {
    const formReference = useRef<HTMLFormElement>(null);

    const updateQuery = updateJourneyName ? `?update=${updateJourneyName}` : '';
    const challengeRedirectPath = `${threeDSChallengePath}${updateQuery}`;
    const absoluteChallengePath = `${
        window.location.origin
    }${relativeUrlWithBasePath(challengeRedirectPath)}`;

    useEffect(() => {
        formReference.current?.submit();
        triggerTimerCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    clientLogger.out(
        `3DS - AuthenticationIframe render - AuthType: ${type}, FormActionUrl: ${redirectUrl}`,
        getFullApplicationIdentifierClient(applicationIdentifier),
    );

    return (
        <StyledIFrame
            title="3ds"
            id="pa-form"
            name="3Diframe"
            src="about:blank"
        >
            <form
                action={redirectUrl}
                method="post"
                name="form"
                target="3Diframe"
                data-testid="3ds-auth-form"
                ref={formReference}
            >
                <input
                    type="hidden"
                    data-testid="hidden-req"
                    name={type === 'ThreeDSTwo' ? 'creq' : 'PaReq'}
                    value={req}
                />
                {type === 'ThreeDSTwo' && (
                    <input
                        type="hidden"
                        data-testid="hidden-three-d-session-data"
                        name="threeDSSessionData"
                        value={transactionId}
                    />
                )}
                {type === 'ThreeDSOne' && (
                    <>
                        <input
                            type="hidden"
                            name="TermUrl"
                            data-testid="hidden-termurl"
                            value={absoluteChallengePath}
                        />
                        <input
                            type="hidden"
                            name="MD"
                            data-testid="hidden-md"
                            value={transactionId}
                        />
                    </>
                )}
                <p>Please click button below to proceed to 3D secure.</p>
                <input type="submit" value="Go" />
            </form>
        </StyledIFrame>
    );
}
