import { getPaymentGatewayCardIdentifier } from './getPaymentGatewayCardIdentifier';
import { processPaymentGateway } from './processPaymentGateway';
import { processPaymentGatewayZeroValue } from './processPaymentGatewayZeroValue';
import {
    ApplicationIdentifier,
    BillingAddressFields,
    CardPaymentInfo,
    ErrorCodes,
    PaymentError,
} from '@tgg/common-types';
import { PostPaymentGatewayResponseBody } from '@tgg/services';
import {
    ErrorResponse,
    getApiErrorResponseMessage,
    isAxiosError,
} from '@tgg/util';

export interface MakePaymentGatewayArguments {
    formInfo: CardPaymentInfo;
    billingAddress: BillingAddressFields;
    paymentToken: string;
    merchantSessionKey: string;
    termsAccepted: boolean;
    authorisedPayee: boolean;
    applicationIdentifier: ApplicationIdentifier;
    totalCost: number;
    setCardIdentifier: (value: string) => void;
    updateJourneyName?: string;
    isReoccurringCreditCard?: boolean;
}

export const makePaymentGateway = async ({
    formInfo,
    authorisedPayee,
    termsAccepted,
    billingAddress,
    updateJourneyName,
    merchantSessionKey,
    paymentToken,
    applicationIdentifier,
    setCardIdentifier,
    isReoccurringCreditCard,
    totalCost,
}: MakePaymentGatewayArguments): Promise<PostPaymentGatewayResponseBody> => {
    let paymentGatewayCardIdentifier;
    let paymentGatewayResponse = {} as PostPaymentGatewayResponseBody;

    try {
        if (totalCost === 0) {
            return await processPaymentGatewayZeroValue({
                paymentToken,
                termsAccepted,
                applicationIdentifier,
            });
        }
    } catch (error) {
        handleError(error, applicationIdentifier);
    }

    try {
        paymentGatewayCardIdentifier = await getPaymentGatewayCardIdentifier({
            merchantSessionKey,
            ...formInfo,
        });
    } catch (error) {
        const errorMessage = getApiErrorResponseMessage(error);

        throw new PaymentError(
            `**${applicationIdentifier}**: clientside call to Opayo failed - Opayo client was not able to get card identifier for this transaction
            errorMessage: ${errorMessage}`,
            '2002',
            { originalError: error },
        );
    }

    setCardIdentifier(paymentGatewayCardIdentifier);

    try {
        paymentGatewayResponse = await processPaymentGateway({
            paymentToken,
            cardIdentifier: paymentGatewayCardIdentifier,
            sessionKey: merchantSessionKey,
            termsAccepted,
            authorisedPayee,
            billingAddress,
            updateJourneyName,
            applicationIdentifier,
            isReoccurringCreditCard,
        });
    } catch (error) {
        handleError(error, applicationIdentifier);
    }

    return paymentGatewayResponse;
};

const handleError = (
    error: unknown,
    applicationIdentifier: ApplicationIdentifier,
) => {
    const errorMessage = getApiErrorResponseMessage(error);

    const statusCode = (isAxiosError(error) && error.response?.status) || 500;

    const errorCode = String(
        (isAxiosError<ErrorResponse>(error) &&
            error.response?.data.error.code) ||
            '2002',
    ) as ErrorCodes;

    throw new PaymentError(
        `**${applicationIdentifier}**: Payment failed - There was an issue with the transaction when calling payment gateway api clientside: ${JSON.stringify(
            errorMessage,
        )} errorCode: ${errorCode} statusCode: ${statusCode}`,
        errorCode,
    );
};
