import {
    type ApplicationIdentifier,
    type Nullable,
    PaymentError,
    type CardPaymentInfo,
    type Promisable,
} from '@tgg/common-types';
import { getApiErrorResponseMessage } from '@tgg/util';

type GetPaymentTokenData = {
    formInfo: CardPaymentInfo;
};

export type GetPaymentToken = (
    data: GetPaymentTokenData,
) => Promisable<Nullable<string>>;

export type ProcessGetPaymentTokenData = {
    getPaymentToken: GetPaymentToken;
    applicationIdentifier: ApplicationIdentifier;
    formInfo: CardPaymentInfo;
};

export const processGetPaymentToken = async ({
    getPaymentToken,
    applicationIdentifier,
    formInfo,
}: ProcessGetPaymentTokenData) => {
    try {
        const paymentToken = await getPaymentToken({ formInfo });

        if (!paymentToken)
            throw new Error(
                `getPaymentToken in processGetPaymentToken returned empty token
                payment token retrieved: ${paymentToken}`,
            );

        return paymentToken;
    } catch (error) {
        const errorMessage = getApiErrorResponseMessage(error);
        throw new PaymentError(
            `**${applicationIdentifier}**: no payment token available for the transaction
            error: ${errorMessage}`,
            '2000',
            { originalError: error },
        );
    }
};
