import { BillingAddressFields, Nullable } from '@tgg/common-types';

export type PostPaymentGatewayRequestBody = {
    paymentToken: string;
    cardIdentifier?: string;
    sessionKey?: string;
    description?: string;
    strongCustomerAuthentication?: {
        notificationUrl?: string;
        browserIP?: string;
        browserAcceptHeader?: string;
        browserColorDepth?: string;
        browserScreenHeight?: string;
        browserScreenWidth?: string;
        browserTZ?: string;
        browserUserAgent?: string;
        challengeWindowSize?: string;
        website?: string;
    };
    reoccurringCreditCard?: boolean;
    termsAccepted: boolean;
    authorisedPayee?: boolean;
    billingAddress?: Nullable<BillingAddressFields>;
};

export type PostPaymentGatewayApiResponse = {
    updatedToken: string;
    redirectUrl?: string;
    type?: 'ThreeDSOne' | 'ThreeDSTwo';
    req?: string;
    uniqueId: string;
};

export type PostPaymentGatewayResponseBody =
    | PostPaymentGatewayResponseWithout3DAuth
    | PostPaymentGateway3DAuthResponse;

export type PostPaymentGatewayResponseWithout3DAuth = {
    status: number;
    success: boolean;
    updatedToken: string;
    uniqueId: string;
};

export type PostPaymentGateway3DAuthResponse = {
    status: number;
    success: boolean;
    updatedToken: string;
    uniqueId: string;
    redirectUrl: string;
    type: 'ThreeDSOne' | 'ThreeDSTwo';
    req: string;
};

export function is3DAuthenticatedResponse(
    response:
        | PostPaymentGatewayResponseWithout3DAuth
        | PostPaymentGateway3DAuthResponse,
): response is PostPaymentGateway3DAuthResponse {
    return (
        (response as PostPaymentGateway3DAuthResponse).redirectUrl !== undefined
    );
}
