import validator from 'card-validator';
import * as z from 'zod';

export const creditCardNumberSchema = z.string().refine(
    cardValue => {
        const { isValid } = validator.number(cardValue);
        return isValid;
    },
    {
        message: 'Enter a valid card number',
    },
);

export const cvvNumberSchema = (cardNumber: string) =>
    z.string().refine(cvvValue => {
        const { card } = validator.number(cardNumber);
        const { isValid } = validator.cvv(cvvValue, card?.code.size);
        return isValid;
    }, 'Invalid CVV number');

const disallowedMetrobankSortCodes = new Set(['23-05-80']);

export const sortCodeSchema = z
    .string()
    .regex(
        // the eslint rule below is disabled because fixing it creates a regex security warning
        // eslint-disable-next-line unicorn/better-regex
        /^\d{2}[- ]?\d{2}[- ]?\d{2}$/,
        'Enter in a sort code in the format xx-xx-xx',
    )
    .refine(
        value => !disallowedMetrobankSortCodes.has(value),
        "Please contact Metro Bank's customer service team before submitting your Direct Debit details. Alternatively, click the link above to make your payments by card.",
    );
