import {
    CardPaymentFormLogging,
    type OnCardPaymentSuccess,
    type PaymentSubmitHandlerArguments,
    type SetUpCardPaymentFlow,
} from '../CardPayment.types';
import { logPaymentIssue } from './logPaymentIssue';
import {
    type BillingAddressFields,
    type CardPaymentInfo,
    type ErrorCodes,
    PaymentError,
} from '@tgg/common-types';

export type UsePaymentParameters = {
    termsAccepted: boolean;
    wref?: string;
    transactionId?: string;
    billingAddress: BillingAddressFields;
    totalCost: number;
    isReoccurringCreditCard?: boolean;
    log: CardPaymentFormLogging;
    setUpThreeDSFlow: SetUpCardPaymentFlow;
    setCardIdentifier: (value: string) => void;
    onErrorCallback: (code: ErrorCodes, fullMessage?: string | Error) => void;
    paymentHandler: (data: PaymentSubmitHandlerArguments) => Promise<void>;
    onBeforeSubmit?: () => void;
    onSuccess: OnCardPaymentSuccess;
};

export function usePayment({
    termsAccepted,
    wref,
    billingAddress,
    transactionId,
    isReoccurringCreditCard,
    totalCost,
    log,
    paymentHandler,
    onErrorCallback,
    setUpThreeDSFlow,
    setCardIdentifier,
    onBeforeSubmit,
    onSuccess,
}: UsePaymentParameters) {
    const handleSubmit = async (formInfo: CardPaymentInfo) => {
        try {
            if (!termsAccepted) {
                return;
            }

            /* istanbul ignore else */
            if (onBeforeSubmit) {
                onBeforeSubmit();
            }

            await paymentHandler({
                formInfo,
                termsAccepted,
                wref,
                billingAddress,
                transactionId,
                isReoccurringCreditCard,
                totalCost,
                setUpThreeDSFlow,
                setCardIdentifier,
                onSuccess,
            });
        } catch (error) {
            logPaymentIssue({ error, log });

            if (error instanceof PaymentError) {
                const { code, message } = error;
                onErrorCallback(code, message);
            } else {
                onErrorCallback('2002', error as Error);
            }

            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    return handleSubmit;
}
