import { CardPaymentFormLogging } from '../../CardPayment.types';
import { PaymentError } from '@tgg/common-types';

export type LogPaymentIssue = {
    error: unknown;
    log: CardPaymentFormLogging;
};

export const logPaymentIssue = ({ error, log }: LogPaymentIssue) => {
    const {
        logger,
        loggerMetaData: { userId, paymentId, ...restLoggerMetadata },
    } = log;

    /* istanbul ignore next */
    if (!error) return;

    if (error instanceof PaymentError) {
        const { code, message, originalError } = error;
        const parsedError = originalError
            ? JSON.stringify(originalError)
            : 'No origin error data present';

        logger.error(
            `Payment failed: 
            code: ${code},
            message: ${message},
            error: ${parsedError}`,
            {
                ...restLoggerMetadata,
                correlationId: userId,
                paymentId,
            },
        );
    } else {
        logger.error(
            `Payment failed:
            message: ${(error as Error).message}
            error: ${JSON.stringify(error)}`,
            {
                ...restLoggerMetadata,
                correlationId: userId,
                paymentId,
            },
        );
    }
};
