import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AuthenticationIframe } from './AuthenticationIframe';
import {
    type CardPaymentErrorCallback,
    type CardPaymentFormContainerProperties,
} from './CardPayment.types';
import { CardPaymentForm } from './CardPaymentForm';
import { MembershipAgreement } from './MembershipAgreement';
import { PaymentCta } from './PaymentCta';
import { useThreeDS, usePayment } from './utils';
import { type BillingAddressFields, PaymentMethod } from '@tgg/common-types';
import { AnalyticsErrorEvent, dispatchEvent, EventKey } from '@tgg/services';
import {
    getErrorFromErrorCode,
    mapBillingAddressFromCustomerAddress,
} from '@tgg/util';

export function CardPayment({
    ui: { agreementTypes, showHeader, submitButtonIconName, submitText },
    paymentData: {
        applicationIdentifier,
        eventType,
        expectedPaymentFrequency,
        totalCost,
        accountData,
        customerAddress,
        threeDSChallengePath,
        transactionId,
        wref,
        isReoccurringCreditCard,
    },
    paymentProccesor: { paymentHandler, paymentGatewayUrl },
    callbacks: {
        onErrorCallback,
        onSuccess,
        onBeforeSubmit,
        onCancel,
        onShowThreeDSCallback,
        onValidate,
    },
    log,
}: CardPaymentFormContainerProperties) {
    const {
        control,
        getValues,
        handleSubmit,
        formState: { isSubmitting, isValid, errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            nameOnCard: '',
            cardNumber: '',
            expiryDate: '',
            securityCode: '',
        },
    });

    const internalErrorCallback =
        createCardPaymentInternalErrorCallback(onErrorCallback);

    const numberOfErrors = Object.keys(errors).length;
    const [cardIdentifier, setCardIdentifier] = useState<string>('');
    const [billingAddress, setBillingAddress] = useState<BillingAddressFields>(
        mapBillingAddressFromCustomerAddress(customerAddress),
    );
    const [
        hasAcceptedAllTermsAndConditions,
        setHasAcceptedAllTermsAndConditions,
    ] = useState<boolean>(false);

    const {
        isThreeDSSecured,
        secureRedirectUrl,
        threeDSRequest,
        uniqueTransactionId,
        threeDSType,
        updateJourneyName,
        triggerTimer,
        setUpThreeDSFlow,
    } = useThreeDS({
        applicationIdentifier,
        transactionId,
        log,
        onErrorCallback: internalErrorCallback,
        onShowThreeDSCallback,
        onSuccess,
    });

    const onFormSubmit = usePayment({
        termsAccepted: hasAcceptedAllTermsAndConditions,
        wref,
        billingAddress,
        isReoccurringCreditCard,
        totalCost,
        log,
        onErrorCallback: internalErrorCallback,
        setCardIdentifier,
        setUpThreeDSFlow,
        paymentHandler,
        onBeforeSubmit,
        onSuccess,
    });

    useEffect(() => {
        if (onValidate) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onValidate(errors);
        }
        // Excluded onValidate from deps as if it triggers a re-render in the parent
        // this effect will also trigger, creating an infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfErrors]);

    const submitButtonDisabled =
        (totalCost > 0 && !isValid) ||
        !hasAcceptedAllTermsAndConditions ||
        isSubmitting;

    if (isThreeDSSecured) {
        return (
            <AuthenticationIframe
                redirectUrl={secureRedirectUrl}
                type={threeDSType as 'ThreeDSOne' | 'ThreeDSTwo'}
                req={threeDSRequest}
                transactionId={uniqueTransactionId}
                triggerTimerCallback={triggerTimer}
                applicationIdentifier={applicationIdentifier}
                threeDSChallengePath={threeDSChallengePath}
                updateJourneyName={updateJourneyName}
            />
        );
    }

    return (
        <>
            <Script src={paymentGatewayUrl} />
            <form onSubmit={handleSubmit(onFormSubmit)}>
                {totalCost > 0 && (
                    <CardPaymentForm
                        billingAddress={billingAddress}
                        cardIdentifier={cardIdentifier}
                        control={control}
                        showHeader={showHeader}
                        getValues={getValues}
                        setBillingAddress={setBillingAddress}
                    />
                )}

                <MembershipAgreement
                    agreementTypes={agreementTypes}
                    selectedPaymentMethod={PaymentMethod.Card}
                    accountData={accountData}
                    expectedPaymentFrequency={expectedPaymentFrequency}
                    wref={wref}
                    onTermsAndConditionsAccepted={
                        setHasAcceptedAllTermsAndConditions
                    }
                />

                <PaymentCta
                    eventType={eventType}
                    disabled={submitButtonDisabled}
                    submitText={submitText}
                    submitButtonIconName={submitButtonIconName}
                    onCancel={onCancel}
                />
            </form>
        </>
    );
}

export const createCardPaymentInternalErrorCallback =
    (onErrorCallback: CardPaymentErrorCallback): CardPaymentErrorCallback =>
    (code, fullMessage) => {
        dispatchEvent<AnalyticsErrorEvent>(EventKey.ERROR, {
            category: code,
            details: getErrorFromErrorCode(code),
        });

        onErrorCallback(code, fullMessage);
    };
