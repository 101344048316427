import { getMerchantSessionKeyUrl } from '../../getPaymentUrls';
import { ApplicationIdentifier, PaymentError } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import { GetSessionKeyResponseBody } from '@tgg/services';
import { getApiErrorResponseMessage, relativeUrlWithBasePath } from '@tgg/util';

export interface GetMerchantSessionKeyArguments {
    applicationIdentifier: ApplicationIdentifier;
}

export const getMerchantSessionKey = async ({
    applicationIdentifier,
}: GetMerchantSessionKeyArguments) => {
    const path = getMerchantSessionKeyUrl(applicationIdentifier);

    try {
        const { data } = await axiosInstance.get<GetSessionKeyResponseBody>(
            relativeUrlWithBasePath(path),
        );

        return data.merchantSessionKey;
    } catch (error) {
        const errorMessage = getApiErrorResponseMessage(error);

        throw new PaymentError(
            `**${applicationIdentifier}**: clientside call to get merchant session key failed for this transaction
            errorMessage: ${errorMessage}`,
            '2001',
            { originalError: error },
        );
    }
};
