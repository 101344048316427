import { Controller } from 'react-hook-form';

import { Asterisk } from '../../../Asterisk';
import { MaskedInput } from '../../../Forms/MaskedInput';
import { StyledInput, StyledLabel } from '../InputFields.styled';
import { ControlInputProperties } from '../InputFields.types';
import { creditCardNumberSchema, validation } from '@tgg/form-validation';

export function CardNumberInput({
    control,
}: {
    control: ControlInputProperties;
}) {
    return (
        <StyledInput>
            <Controller
                name="cardNumber"
                control={control}
                rules={{
                    required: 'Enter a valid card number',
                    validate: value => {
                        return (
                            validation(creditCardNumberSchema, value)
                                .errors?.[0] ?? true
                        );
                    },
                }}
                render={({
                    field: { value, onChange, onBlur },
                    fieldState: { invalid, isDirty, error },
                }) => {
                    return (
                        <>
                            <StyledLabel htmlFor="card-number">
                                Number on the card
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <MaskedInput
                                mask="creditCard"
                                value={value}
                                id="card-number"
                                inputProps={{
                                    inputMode: 'numeric',
                                }}
                                onChange={onChange}
                                onBlur={onBlur}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                                placeholder="Enter your card number"
                                autoComplete="cc-number"
                            />
                        </>
                    );
                }}
            />
        </StyledInput>
    );
}
