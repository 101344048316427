import { type ApplicationIdentifier } from '@tgg/common-types';

export const getPaymentUrl = (applicationIdentifier: ApplicationIdentifier) =>
    applicationIdentifier === 'commerce'
        ? '/api/proxy/join/paymentGateway/'
        : '/api/proxy/payments/paymentGateway/';

export const getPaymentOutcomeUrl = (
    applicationIdentifier: ApplicationIdentifier,
) =>
    applicationIdentifier === 'commerce'
        ? '/api/proxy/join/paymentOutcome/'
        : '/api/proxy/payments/paymentOutcome/';

export const getChallangeOutcomeUrl = (
    applicationIdentifier: ApplicationIdentifier,
) =>
    applicationIdentifier === 'commerce'
        ? '/join-now/challenge-outcome/'
        : '/challenge-outcome/';

export const getMerchantSessionKeyUrl = (
    applicationIdentifier: ApplicationIdentifier,
) =>
    applicationIdentifier === 'commerce'
        ? '/api/proxy/join/sessionKey/'
        : '/api/proxy/payments/sessionkey/';
