import { Controller } from 'react-hook-form';

import { Asterisk } from '../../../Asterisk';
import { TextInput } from '../../../Forms/TextInput';
import { StyledInput, StyledLabel } from '../InputFields.styled';
import { ControlInputProperties } from '../InputFields.types';
import { cardNameValidator } from '@tgg/form-validation';

export function CardholderNameInput({
    control,
}: {
    control: ControlInputProperties;
}) {
    return (
        <StyledInput>
            <Controller
                name="nameOnCard"
                control={control}
                rules={{
                    validate: {
                        validator: cardNameValidator,
                    },
                }}
                render={({
                    field: { value, onChange, onBlur },
                    fieldState: { invalid, isDirty, error },
                }) => {
                    return (
                        <>
                            <StyledLabel htmlFor="name-on-card">
                                Name on the card
                                <Asterisk spaceBefore />
                            </StyledLabel>
                            <TextInput
                                value={value}
                                errorMessage={error?.message}
                                isInvalid={invalid}
                                placeholder="Enter name on the card"
                                id="name-on-card"
                                onChange={onChange}
                                onBlur={onBlur}
                                iconElementRight={{
                                    name:
                                        !invalid && isDirty ? 'tick' : 'blank',
                                }}
                                autoComplete="cc-name"
                            />
                        </>
                    );
                }}
            />
        </StyledInput>
    );
}
